import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { toCapitalize } from '../../utils/functions';
import { useGlobalStyles } from '../../utils/styles';
import { calendario, calendarioFuente, calendarioUpdatedAt } from '../../utils/transparencia-difusion/constants';

const CalendarioPensiones = () => {
  const theme = useTheme();
  moment.locale('es');
  const globalClasses = useGlobalStyles();
  const fuente = 'Dirección de Finanzas';
  const updatedAt = '01 de enero de 2024';

  return (
    <Layout>
      <SEO title='Calendario pensiones' />

      <div className={globalClasses.container}>
        <Header title='Calendario de pagos de jubilados y pensionados 2024' />

        <div className={globalClasses.content}>
          <TableContainer component={Paper} elevation={1}>
            <Table size='small'>
              <TableHead style={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff' }}>No.</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff' }}>Mes</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: '#fff' }}>Fecha de pago</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calendario.map((item, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{(index + 1)}</TableCell>
                    <TableCell>{item.mes}</TableCell>                    
                    <TableCell>{toCapitalize(moment(item.fecha, 'DD/MM/YYYY').locale('es').format('dddd[,] DD [de] MMMM [de] YYYY'))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography style={{ marginTop: theme.spacing(5), fontStyle: 'italic' }} component='blockquote'>
            Artículo 151 del Reglamento de la Ley de Seguridad Social del Estado de Tabasco, que a la letra establece: "El pago de la pensión será mensual y se hará por conducto de una Institución de Banca Múltiple, vía transferencia electrónica, de conformidad al calendario que para tal efecto establezca el ISSET."
          </Typography>
        </div>

        <UpdatedInfo fuente={calendarioFuente} updatedAt={calendarioUpdatedAt} />
      </div>
    </Layout>
  );
};

export default CalendarioPensiones;